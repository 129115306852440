<template>
  <div
    component-name="form-filters"
    class="flex flex-col items-stretch"
    :class="{
      'gap-2xl': useSearch,
    }"
  >
    <header
      v-if="useSearch"
      class="flex flex-col w-full bg-skin-base transition-transform pt-30"
      :class="{
        'sticky top-0 left-0 z-2': useStickyHeader,
        'duration-300 translate-y-0 ease-in-out':
          useStickyHeader && usePaddingTop,
        'duration-500 -translate-y-[120px] ease-out':
          useStickyHeader && !usePaddingTop,
      }"
    >
      <div class="flex justify-start w-full h-16 gap-xl">
        <btn-tab
          :title="$t.labels.filter_by"
          icon="filters"
          :pressed="currentTab === 'filters'"
          class="h-full"
          @click="currentTab = 'filters'"
        />

        <btn-tab
          :title="$t.labels.search"
          icon="search"
          :pressed="currentTab === 'search'"
          class="h-full"
          @click="currentTab = 'search'"
        />
      </div>

      <divider class="w-full" />
    </header>

    <div
      class="w-full transition-transform"
      :class="{
        'duration-300 translate-y-0 ease-in-out':
          useStickyHeader && usePaddingTop,
        'duration-500 -translate-y-[120px] ease-out':
          useStickyHeader && !usePaddingTop,
      }"
    >
      <transition-fade duration="quick">
        <input-form
          v-if="!useSearch || currentTab === 'filters'"
          key="filter-by"
          id="form-filters"
          type="form"
          class="flex flex-col items-stretch gap-2xl w-full"
          :use-submit-btn="false"
          :actions="false"
          @change="handleChange"
          @update:value="handleUpdate"
          @input="handleInput"
          v-model="model"
        >
          <!-- ALL / INDOOR ONLY / OUTDOOR ONLY -->
          <div v-if="useIndoorOutdoor" class="overflow-hidden flex pr-xl">
            <form-kit
              type="radio"
              options-class="flex gap-2xl"
              name="search-in"
              :options="[
                { label: $t.labels.all, value: 'all' },
                { label: $t.labels.indoor, value: 'indoor' },
                { label: $t.labels.outdoor, value: 'outdoor' },
              ]"
            />
          </div>
          <!-- end ALL / INDOOR ONLY / OUTDOOR ONLY -->
          <!-- ACTIVE FILTERS -->
          <transition-fade>
            <div
              v-if="activeFilters && activeFilters.length"
              class="flex flex-col gap-md"
            >
              <divider />

              <div
                class="text-heading-6 text-skin-base mt-2xl"
                v-html="$t.labels.active_filters"
              ></div>

              <div class="flex flex-wrap items-baseline gap-sm">
                <btn-chip
                  v-for="chip in activeFilters"
                  :title="toCapitalize(chip.title)"
                  :val="chip.value"
                  @remove="handlerRemoveFilter"
                />
              </div>

              <div
                class="inline-flex text-overline-small/none whitespace-nowrap text-skin-muted"
                v-html="
                  total == 1
                    ? $t.labels.search_total_results_singular
                    : `${total} ${$t.labels.search_total_results_plural}`
                "
              ></div>
              <div>
                <btn-nav
                  size="big"
                  :title="$t.labels.reset_filters"
                  @click="resetForm"
                />
              </div>
            </div>
          </transition-fade>
          <!-- end ACTIVE FILTERS -->

          <!-- FILTERS -->
          <collapse-group
            v-if="!$route.query.keyword"
            :always-open="true"
            class="w-full"
          >
            <collapse v-for="facet in computedFacets" :title="facet.title">
              <template #header="collapseProps">
                <btn
                  v-if="collapseProps.title"
                  button-tag="summary"
                  :title="toCapitalize(collapseProps.title)"
                  class="flex justify-between items-center truncate gap-xl text-skin-base bg-skin-base h-[74px] px-md hover:bg-skin-inverse hover:text-skin-inverse transition-colors duration-300 ease-in-out cursor-pointer"
                >
                  <div
                    class="!block text-body-big text-skin-base group-hover/btn:text-skin-inverse transition-colors duration-300 ease-in-out"
                    v-html="toCapitalize(collapseProps.title)"
                  ></div>

                  <svg-icon
                    size="regular"
                    :icon="
                      collapseProps.isOpen
                        ? collapseProps.icons.opened
                        : collapseProps.icons.closed
                    "
                  />
                </btn>
              </template>

              <div class="pl-md w-full">
                <form-kit
                  type="checkbox"
                  :options="facet.items"
                  :name="facet.value"
                />
              </div>
            </collapse>
          </collapse-group>
          <!-- end FILTERS -->
        </input-form>

        <input-form
          v-else-if="currentTab === 'search'"
          key="search"
          type="form"
          class="flex flex-col items-stretch w-full gap-2xl"
          :use-submit-btn="false"
          :actions="false"
          @input="throttledHandleKeywordUpdate"
        >
          <input-keyword
            :label="$t.labels.search_by_name_code"
            :placeholder="$t.labels.type_product_name_code"
            :help="$t.labels.search_product_sugggestion"
            class="w-full"
          />

          <!-- PLACEHOLDER TO MATCH FILTER & SEARCH TABS WIDTH -->
          <div
            v-if="useIndoorOutdoor"
            inert
            class="overflow-hidden flex max-h-[0px] pr-xl"
          >
            <form-kit
              type="radio"
              options-class="flex gap-2xl"
              name="placeholder"
              :disabled="true"
              :options="[
                { label: $t.labels.all, value: 'all' },
                { label: $t.labels.indoor, value: 'indoor' },
                { label: $t.labels.outdoor, value: 'outdoor' },
              ]"
            />
          </div>
          <!-- end PLACEHOLDER TO MATCH FILTER & SEARCH TABS WIDTH -->
        </input-form>
      </transition-fade>
    </div>
  </div>
</template>

<script setup>
import { useGeolistStore } from "@/stores/geolist";
import { reset } from "@formkit/core";

const props = defineProps({
  facets: {
    type: String,
    required: true,
  },
  total: {
    type: Number,
    required: true,
  },
  useSearch: {
    type: Boolean,
    required: false,
  },
  useIndoorOutdoor: {
    type: Boolean,
    required: false,
  },
  useStickyHeader: {
    type: Boolean,
    required: false,
  },
  usePaddingTop: {
    type: Boolean,
    required: false,
  },
});
const router = useRouter();
const route = useRoute();
const currentTab = ref(route.query.keyword ? "search" : "filters");
const searchIn = ref();
const activeFilters = ref([]);
const geolistStore = useGeolistStore();
const emit = defineEmits(["update", "keyword-update"]);
const model = ref({});
const enabled = ref(true);
const _facets = ref(JSON.parse(props.facets || "[]"));
const computedFacets = ref(computeFacets(_facets.value));
const { pushDataLayer } = useDataLayer();

if (route.query && !route.query.keyword) {
  model.value = Object.fromEntries(
    Object.entries(route.query).map(([key, value]) => [
      key,
      value.split(",").filter((item) => item),
    ])
  );

  if (route.query["search-in"]) {
    model.value["search-in"] = route.query["search-in"];
  } else if (props.useIndoorOutdoor) {
    model.value["search-in"] = "all";
  }
}

function handlerRemoveFilter(val) {
  for (const key in model.value) {
    if (Array.isArray(model.value[key])) {
      model.value[key] = model.value[key].filter((item) => item != val);
    } else {
      delete model.value[key];
    }
  }
}

function handleUpdate(event) {}

function handleInput(event) {
  const filters = {};
  if (event["search-in"]) {
    filters["search-in"] = event["search-in"];
  }
  if (searchIn.value != event["search-in"]) {
    model.value = {
      "search-in": event["search-in"],
    };
    searchIn.value = event["search-in"];
    reset("form-filters", {
      "search-in": searchIn.value,
    });
    // return router.replace({
    //   path: route.path,
    //   query: filters,
    // });
  }
  // flat arrays inside an object
  const flat = Object.values(event).flat();
  // compact an array
  const compact = flat.filter((item) => item);
  for (const facet of _facets.value) {
    let ids = [];
    for (const item of facet.items) {
      if (compact.includes(item.value)) {
        ids.push(item.value);
      }
    }
    if (ids.length) {
      filters[facet.value] = ids.join(",");
    }
  }
  router.replace({
    path: route.path,
    query: filters,
  });
}

function resetForm(event) {
  // router.replace({
  //   path: route.path,
  //   query: {
  //     "search-in": "all",
  //   },
  // });
  // model.value = {};
  reset("form-filters", {
    "search-in": "all",
  });
}

function handleChange(event) {}

const dataLayerItems = [];

function updateActiveFacets(query) {
  Object.keys(query).forEach((key) => {
    if (typeof query[key] == "string") {
      query[key].split(",").forEach((filter) => {
        const facetGroup = _facets.value.filter((el) => el.value == key)[0];
        if (facetGroup) {
          const facet = facetGroup.items.filter((el) => el.value == filter)[0];
          if (facet) {
            const label = facet.label.replace(/\s\(\d+\)/, "");

            if (facetGroup.value == "pa_formato-group-cm") {
              if (!dataLayerItems.includes(label)) {
                dataLayerItems.push(label);
                pushDataLayer({
                  event: "click_formato_prodotto",
                  format: label,
                });
              }
            }

            activeFilters.value.push({
              title: label,
              value: facet.value,
            });
          }
        }
      });
    }
  });
}

function getIsFilterActive(cat, value) {
  return route.query[cat]?.includes(value);
}

watch(
  () => route.query,
  (value) => {
    if (!value || value.keyword) return;
    activeFilters.value = [];
    updateActiveFacets(value);
  }
);

watch(
  () => props.facets,
  (value) => {
    if (!value || value.keyword) return;
    _facets.value = JSON.parse(value);
    computedFacets.value = computeFacets(_facets.value);
  }
);

watch(
  () => currentTab.value,
  (value, old) => {
    const isEmpty = (obj) => {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    const query = { ...route.query };
    if (!isEmpty(query)) {
      router.replace({
        path: route.path,
        query: "",
      });
    }

    // if (isEmpty(route.query) && value == "filters" && old == "search") {
    //   emit("keyword-update", "");
    // } else {
    //   router.replace({
    //     path: route.path,
    //     query: "",
    //   });
    // }
  }
);

updateActiveFacets(route.query);

searchIn.value = model.value["search-in"];

function computeFacets(_facets) {
  const facets = _facets.map((facet) => {
    facet.items = facet.items.filter((item) => {
      let count = parseInt(item.count);
      let total = parseInt(props.total);
      let value = item.value.toString();

      if (facet.value == "nazione_") {
        item.label = geolistStore.countries.find(
          (country) => country.value.toUpperCase() == item.label.toUpperCase()
        )?.label;
      }

      // if (count > total && !getIsFilterActive(facet.value, value)) {
      //   count = count - total;
      // }

      item.label = `${toCapitalize(item.label || "")} (${count})`;
      // item.label = `${toCapitalize(item.label)}`;
      // getIsFilterActive(facet.value, value);

      if (
        /* parseInt(item.count) == total && */
        !getIsFilterActive(facet.value, value) &&
        parseInt(item.count) == 0
      ) {
        item.attrs = { disabled: true };
      }

      return true;
      // return count > 0;
      // return (
      //   count > 0 && !(count == total && !getIsFilterActive(facet.value, value))
      // );
    });
    return facet;
  });
  return facets.filter((facet) => facet.items.length);
}

// to first letter uppercase, rest lowercase
function toCapitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

async function handleKeywordUpdate(request) {
  emit("keyword-update", request.keyword);
}

const throttledHandleKeywordUpdate = useThrottleFn(
  handleKeywordUpdate,
  500,
  true,
  false,
  true
);
</script>

<style>
[data-disabled="true"] {
  opacity: 0.5;
  pointer-events: none;
}
</style>
